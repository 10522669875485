import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Link from '../utils/link'

import { Logo, Search, Cross, Chevron, LeftArrow } from './icons'

class Header extends Component {

  state = {
    offCanvas: false, 
    offCanvasProperties: false, 
    offCanvasProjects: false, 
    offCanvasAbout: false, 
    scrolled: false, 
    headerInvert: false, 
    direction: 0, 
    prevDirection: 0, 
    prevScroll: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, headerInvert, scrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    // Bottom of page
    if (document?.querySelector('.home-banner')) {
      if (curScroll > document.querySelector('.home-banner').offsetHeight - 60) {
        headerInvert = true
      } else {
        headerInvert = false
      }
      if (curScroll > 2000) {
        document.querySelector('.quick-links').classList.add('scrolled')
      } else {
        document.querySelector('.quick-links').classList.remove('scrolled')
      }
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      this.setState({
        scrolled, headerInvert, direction, prevDirection, prevScroll,
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false, offCanvasProperties: false, offCanvasProjects: false, offCanvasAbout: false })
    this.props.setSearch(false)
    document.querySelector('body').classList.remove('scrolling')
  }

  _hideOffCanvasSubNav = () => {
    this.setState({ offCanvasAbout: false })
    this.setState({ offCanvasProperties: false })
    this.setState({ offCanvasProjects: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
    this._hideOffCanvasSubNav()
    document.querySelector('body').classList.add('scrolling')
  }

  _toggleOffCanvasProperties = () => {
    this.setState({ offCanvasProperties: !this.state.offCanvasProperties, offCanvasAbout: false, offCanvasProjects: false })
    this.props.setSearch(false)
  }

  _toggleOffCanvasProjects = () => {
    this.setState({ offCanvasProjects: !this.state.offCanvasProjects, offCanvasProperties: false, offCanvasAbout: false })
    this.props.setSearch(false)
  }

  _toggleOffCanvasAbout = () => {
    this.setState({ offCanvasAbout: !this.state.offCanvasAbout, offCanvasProperties: false, offCanvasProjects: false })
    this.props.setSearch(false)
  }

  openSearch = () => {
    if (
      this.props.location.pathname !== '/buy/' && 
      this.props.location.pathname !== '/lease/' && 
      this.props.location.pathname !== '/sold/' && 
      this.props.location.pathname !== '/project-listings/'
    ) {
      navigate('/buy/')
    }

    this.setState({ offCanvasAbout: false, offCanvasProperties: false, offCanvasProjects: false })
    this.props.setSearch(!this.props.search)
  }

  render() {

    let { offCanvas, scrolled, headerInvert, offCanvasProperties, offCanvasProjects, offCanvasAbout } = this.state

    let props = {
      onClick: this._hideOffCanvas, activeClassName: 'active',
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--inverse'
    if (headerInvert) headerClass += ' header--invert'
    if (offCanvas) headerClass += ' header--off-canvas-active'
    if (this.props.pageContext.header) headerClass += ` header--${this.props.pageContext.header}`
    if (this.props.search) headerClass += ' header--search-active'

    return (<>
      <header className={ headerClass }>
        <div className='header__inner'>
          <Link to='/' title='Peake' className='header__logo' { ...props }>
            <Logo color='#FFFFFF'/>
          </Link>
          <nav className='header__nav'>
            <ul>
              <li><Link onClick={ this._toggleOffCanvasProperties }>Property</Link></li>
              <li><Link to='/projects/' {...props}>Projects</Link></li>
              <li><Link onClick={ this._toggleOffCanvasAbout }>About</Link></li>
              <li><Link to='/contact/' { ...props }>Contact</Link></li>
              <li><Link to='/appraisal/' { ...props }>What is your property worth</Link></li>
            </ul>
          </nav>
          <button className={`header__search ${this.props.location.pathname === '/' ? 'home' : ''} ${this.props.search ? 'open' : ''}`} onClick={ this.openSearch }>
            <span>Start your property search</span>
            <div className={`header__search-icon${this.props.search ? '' : ' pulse'}`}>
              { this.props.search ? <Cross color='#3B1F77'/> : <Search color='#3B1F77'/> }
            </div>
          </button>
          { !offCanvas && <button type='button' onClick={ this._toggleOffCanvas } className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
            <span className='lines'></span>
          </button> }

          { offCanvas && <button onClick={ this._hideOffCanvas } className='off-canvas__close'>
            <Cross color='#F1F1F2'/>
          </button> }
        </div>
      </header>

      <div className={ `off-canvas__properties ${ offCanvasProperties && 'off-canvas__properties-active' }` }>
        <div className='off-canvas__properties-back'>
          <button onClick={ this._hideOffCanvasSubNav }><LeftArrow/>Back to Main Menu</button>
        </div>
        <div className='off-canvas__properties-bg' onClick={ this._hideOffCanvasSubNav }></div>
        <div className='off-canvas__properties-inner'>
          <div className='off-canvas__properties-col'>
            <h3>Buying</h3>
            <ul>
              <li><Link to='/buy/' {...props}>View Listings</Link></li>
              <li><Link to='/upcoming-inspections/' {...props}>Open Homes</Link></li>
              <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
            </ul>
          </div>

          <div className='off-canvas__properties-col'>
            <h3>Leasing</h3>
            <ul>
              <li><Link to='/lease/' {...props}>View Listings</Link></li>
              <li><Link to='/upcoming-inspections-lease/' {...props}>Open Homes</Link></li>
              <li><Link to='/appraisal/' {...props}>Request an Appraisal</Link></li>
            </ul>
          </div>

          <div className='off-canvas__properties-col'>
            <h3>Selling</h3>
            <ul>
              <li><Link to='/appraisal/' {...props}>Request an Appraisal</Link></li>
              <li><Link to='/sold/' {...props}>Recent Sales</Link></li>
              <li><Link to='/contact/' {...props}>Contact a Specialist</Link></li>
            </ul>
          </div>

          <div className='off-canvas__properties-col'>
            <h3>Investment</h3>
            <ul>
              <li><Link to='/projects/' {...props}>Peake Projects</Link></li>
              <li><Link to='/landlords/' {...props}>Property Management</Link></li>
              <li><Link to='/contact/' {...props}>Contact an Investment Specialist</Link></li>
            </ul>

            <div className='worth'>
              <Link to='/appraisal/' {...props}>What’s your property worth? <Chevron color='#FFFFFF'/></Link>
            </div>
          </div>
        </div>
      </div>

      <div className={ `off-canvas__projects ${ offCanvasProjects && 'off-canvas__projects-active' }` }>
        <div className='off-canvas__projects-back'>
          <button onClick={ this._hideOffCanvasSubNav }><LeftArrow/>Back to Main Menu</button>
        </div>
        <div className='off-canvas__projects-bg' onClick={ this._hideOffCanvasSubNav }></div>
        <div className='off-canvas__projects-inner'>
          <div className='off-canvas__projects-col'>
            <h3>Projects</h3>
            <ul>
              <li><Link to='/projects/' {...props}>About Peake Projects</Link></li>
              <li><Link to='/project-listings/' {...props}>View Listings</Link></li>
              <li><Link to='/projects-sold/' {...props}>Projects Sold</Link></li>
              <li><Link to='/projects-team/' {...props}>Find a Projects Agent</Link></li>
              <li><Link to='/blog/?category=Projects' {...props}>Related News</Link></li>
            </ul>
          </div>
          <div className='off-canvas__projects-col'></div>
        </div>
      </div>

      <div className={ `off-canvas__about ${ offCanvasAbout && 'off-canvas__about-active' }` }>
        <div className='off-canvas__about-back'>
          <button onClick={ this._hideOffCanvasSubNav }><LeftArrow/>Back to Main Menu</button>
        </div>
        <div className='off-canvas__about-bg' onClick={ this._hideOffCanvasSubNav }></div>
        <div className='off-canvas__about-inner'>
          <div className='off-canvas__about-col'>
            <h3>About</h3>
            <ul>
              <li><Link to='/team/' {...props}>Our Team</Link></li>
              <li><Link to='/about/' {...props}>Who we are</Link></li>
              <li><Link to='/blog/' {...props}>Peake Piece</Link></li>
              <li><Link to='/careers/' {...props}>Careers at Peake</Link></li>
            </ul>
          </div>
          <div className='off-canvas__about-col'></div>
        </div>
      </div>

      <div className={ `off-canvas ${ offCanvas && 'off-canvas--active' }` }>
        <div className='off-canvas__mobile'>
          <div className='off-canvas__nav'>
            <ul>
              <li><Link onClick={ this._toggleOffCanvasProperties } to='/'>Property <Chevron/></Link></li>
              <li><Link to='/projects/' {...props}>Projects</Link></li>
              <li><Link onClick={ this._toggleOffCanvasAbout } to='/'>About <Chevron/></Link></li>
              <li><Link to='/contact/' {...props}>Contact</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>)
  }
}

export default Header
