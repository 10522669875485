import React, { Component } from 'react'
import Link from '../utils/link'
import { FooterLogo, ArrowTop } from './icons'
import NewsletterForm from './newsletter'

// Component to fix beforeChange bug rerendering slick
class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (<>{this.props.children}</>)
  }
}

class Footer extends Component {

  // function to smooth scroll to top
  scrollToTop = () => {
    window && window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__back'>
            <button onClick={() => this.scrollToTop()}>
              Back to top
              <ArrowTop color='#FFFFFF'/>
            </button>
          </div>

          <div className='footer__logo'>
            <FooterLogo color='#FFFFFF'/>
          </div>

          <div className='footer__content'>
            <div className='footer__cols'>
              <div className='footer__col'>
                <h3>Handy Links</h3>
                <ul>
                  <li><Link to='/projects/'>Peake Projects</Link></li>
                  <li><Link to='/upcoming-inspections/'>Open Homes</Link></li>
                  <li><Link to='/contact/'>General Enquiries</Link></li>
                  <li><Link to='/team/'>Find an agent</Link></li>
                </ul>
              </div>

              <div className='footer__col'>
                <h3>Berwick</h3>
                <p>18 Langmore Lane, Berwick VIC 3806</p>
                <Link to='tel:+61397075300'>03 9707 5300</Link>
                <Link to='mailto:reception@peakere.com.au'>Email Berwick</Link>
              </div>

              <div className='footer__col'>
                <h3>Officer</h3>
                <p>439 Princes Highway Officer VIC 3809</p>
                <Link to='tel:+61397075300'>03 9707 5300</Link>
                <Link to='mailto:reception@peakere.com.au'>Email Officer</Link>
              </div>

              <div className='footer__col'>
                <h3>Connect</h3>
                <ul>
                <li><Link to='https://www.linkedin.com/company/peake-real-estate/'>LinkedIn</Link></li>
                <li><Link to='https://www.instagram.com/peakerealestate/'>Instagram</Link></li>
                <li><Link to='https://www.facebook.com/chrispeakerealestate/'>Facebook</Link></li>
                <li><Link to='https://www.youtube.com/user/ChrisPeakeRealEstat1/'>Youtube</Link></li>
                </ul>
              </div>
            </div>

            <div className='footer__subscribe'>
              <h3>Subscribe to Peake Piece</h3>
              <p>We’ll only email you occasionally with a curated update of what’s happening in the area.</p>
              <BlockUpdate>
                <NewsletterForm form={7} />
              </BlockUpdate>
            </div>
          </div>

          <div className='footer__credit'>
            <div className='footer__left'>
              <ul>
                <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                <li><Link to='/due-diligence/'>Due Diligence</Link></li>
                <li><Link to='/disclaimer/'>Disclaimer</Link></li>
              </ul>
            </div>

            <div className='footer__right'>
              <p>© 2024 Peake Real Estate. All rights reserved.</p>
              <p><Link to='https://atollon.com.au?utm_source=peakere.com.au&utm_campaign=attr&utm_content=footerlink&utm_medium=affiliate'>Design</Link> + <Link to="https://atollon.com.au/real-estate-design-agency?utm_source=peakere.com.au&utm_campaign=attr&utm_content=footerlink&utm_medium=affiliate">Development</Link></p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
