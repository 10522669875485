import React from 'react'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ children, location, pageContext }) => {

  const [search, setSearch] = React.useState(false)

  const data = layoutQuery()

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { search: search, setSearch: setSearch })
  )

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} search={search} setSearch={setSearch} />
      <main>{childrenWithProps}</main>
      <Footer data={data} />
    </>
  )
}

export default Layout
