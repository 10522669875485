import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment TeamCard on WpTeam {
      id
      title
      uri
      acf {
        cardImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
        bio
        position
        filter
        projects
        email
        phone
      }
      boxData {
        id
        office_id
      }
    }
    fragment PropertyCard on WpProperty {
      id
      uri
      slug
      title
      date
      staff
      images {
        url
        index
      }
      inspections {
        inspection_date
        start_time
        end_time
      }
      boxData {
        auction_date
        auction_time
        display_price
        campaign_start_date
        sale_price
        displayAddress
        website_status
        status
        suburb_undisclosed
        hidden
        price_undisclosed
        price_to
        price_from
        sale_date
        under_offer
        property {
          unit
          baths
          beds
          cars
          garages
          number
          street_name
          street_type
          suburb
          state
          postcode
          land_size
          land_measure
          tags
        }
      }
    }
    fragment RentalCard on WpRental {
      id
      uri
      slug
      title
      date
      staff
      images {
        url
        index
      }
      boxData {
        advertisable
        display_price
        date_available
        displayAddress
        website_status
        status
        suburb_undisclosed
        hidden
        price_undisclosed
        price_to
        price_from
        property {
          unit
          baths
          beds
          cars
          garages
          number
          street_name
          street_type
          suburb
          state
          postcode
        }
      }
    }
    fragment PostCard on WpPost {
      id
      uri
      title
      date
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
}

export default useLayoutQuery
