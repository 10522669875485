import { useStaticQuery, graphql } from "gatsby"

const useFormQuery = () => {
  const data = useStaticQuery(graphql`
    query GfFormQuery {
      allWpGfForm {
        nodes {
          databaseId
          formFields {
            nodes {
              type
              databaseId
              ... on WpHiddenField {
                defaultValue
                label
              }
              ... on WpRadioField {
                label
                choices {
                  text
                  value
                }
              }
              ... on WpCheckboxField {
                label
                choices {
                  text
                  value
                }
              }
              ... on WpEmailField {
                isRequired
                label
                placeholder
              }
              ... on WpTextField {
                defaultValue
                isRequired
                label
                placeholder
              }
              ... on WpTextAreaField {
                defaultValue
                isRequired
                label
                placeholder
                size
              }
              ... on WpSelectField {
                label 
                isRequired
                choices {
                  text
                  value
                }
              }
            }
          }
        }
      }
    }
  `)
  
  return data.allWpGfForm.nodes
}

export default useFormQuery