import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Chevron } from './icons'
import Loading from '../assets/images/loading.svg'

import useFormQuery from '../hooks/use-form-query'

const RenderField = ({ el, handleChange, error }) => {

  // Find error message for this field, return as JSX paragraph
  const errorMessage = error?.map((e, i) => e.id === el.databaseId && <p key={i} className='form__row-error'>{e.message}</p>)

  let fields = {
    'TEXT': (el) => {
      return (
        <div className='form__row'>
          <div className='form__input'>
            <input 
              type='text' 
              name={el.label}
              placeholder={el.placeholder} 
              onChange={handleChange} 
              required={el.isRequired} 
            />
          </div>
          { errorMessage }
        </div>
      )
    },
    'EMAIL': (el) => {
      return (
        <div className='form__row'>
          <div className='form__input'>
            <input 
              type='email' 
              name={el.label} 
              placeholder={el.placeholder} 
              onChange={handleChange} 
              required={el.isRequired} 
            />
          </div>
          { errorMessage }
        </div>
      )
    },
  }[el.type]

  if (!fields) return null

  return fields(el)
}

const submitMutation = (formId, fields, user) => {

  const fieldValues = fields.map(el => {
    if (el.type === 'EMAIL') {
      return `
      {
        id: ${el.databaseId}
        emailValues: {
          confirmationValue: "${el.value}"
          value: "${el.value}"
        }
      }
      `
    }
    return `
      {
        id: ${el.databaseId}
        value: "${el.value}"
      }
    `
  }).join('')

  return `
    mutation {
      submitGfForm(
        input: {
          id: ${formId}
          entryMeta: {
            ip: "${user.ip}"
          }
          fieldValues: [
            ${fieldValues}
          ]
          saveAsDraft: false
          sourcePage: 1
          targetPage: 0
        }
      ) {
        confirmation {
          type    
          message
          url
        }
        errors {
          id
          message
        }
        entry {
          id
          ... on GfSubmittedEntry {
            databaseId
          }
          ... on GfDraftEntry {
            resumeToken
          }
        }
      }
    }  
  `
}

const NewsletterForm = (props) => {
  const { form, title, description, bannerImage, projects } = props

  const forms = useFormQuery()

  const gform = forms.find(el => el.databaseId === form)

  const [user, setUser] = useState({})
  const [result, setResult] = useState(gform?.formFields?.nodes || [])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then(response => response.text())
      .then(text => {
        const data = text.trim().split('\n').reduce(function (obj, pair) {
          pair = pair.split('=');
          obj[pair[0]] = pair[1];
          return obj
        }, {});
        setUser(data);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  // If can't find form, do not display anything.
  if (!gform) return null

  const handleChange = (event, { databaseId }) => {
    const value = event.target.value
    const index = gform.formFields.nodes.findIndex(el => el.databaseId === databaseId)
    setResult(prevState => {
      return prevState.map((el, i) => {
        if (i === index) {
          el.value = value
        }
        return el
      })
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true)

    const mutation = submitMutation(gform.databaseId, result, user)

    fetch(`https://peake.atollon.com.au/graphql`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: mutation,
        variables: {
        }
      }),
    })
      .then(response => response.json())
      .then(json => {
        setLoading(false)
        if (json.data.submitGfForm?.errors || json.errors) {
          setError(json.data.submitGfForm?.errors || json.errors)
        } else if (json.data.submitGfForm?.confirmation) {
          setSuccess(json.data.submitGfForm.confirmation)
        }
      })
  }

  // Main fields
  const fields = gform.formFields.nodes.filter(
    el => el.type !== 'CHECKBOX' && el.label !== 'Sidebar'
  ).map(
    (el, i) => (
      <RenderField 
        key={i} 
        el={el} 
        i={i} 
        error={error} 
        handleChange={e => handleChange(e, el)} 
      />
    )
  )
  // Sidebar fields
  const checkboxes = gform.formFields.nodes.filter(
    el => el.type === 'CHECKBOX' && el.label === 'Sidebar'
  ).map(
    (el, i) => (
      <RenderField 
        key={i} 
        el={el} 
        i={i} 
        error={error} 
        handleChange={e => handleChange(e, el)} 
      />
    )
  )

  return (
    <form name='newsletter' className='newsletter' onSubmit={onSubmit}>
      {success ? parse(success.message) : (
        <>
          {fields}
          <button type='submit' disabled={loading}>
            { loading ? <img src={Loading} alt='Peake' /> : <Chevron color='#FFFFFF'/> }
          </button>
        </>
      )}
    </form>
  )
}

export default NewsletterForm